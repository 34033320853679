import { Link } from "react-router-dom";
import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import ModalP from "../components/modal/ModalP";

export default function About() {
  const [isOpen, setIsOpen] = useState(false);
  const OpenModal = () => setIsOpen(true);
  const CloseModal = () => setIsOpen(false);

  return (
    <LayoutOne>
      <Imgsection image="/images/banner15.jpg" title="About Us" />
      <div className=" flex flex-col text-[12px] md:text-[16px]  ">
        <div className=" flex flex-col pt-[30px] ">
          <div className=" bg-white w-[100%] pt-[35px] px-[30px] text-black min-h-[45px] ">
            <p className=" pb-[20px] text-sub_main text-[20px] md:text-[30px] ">
              ABOUT US
            </p>
            <div>
              <p className=" pt-[20px] text-[13px] md:text-[16px] ">
                Built on the long-respected principle in Medicine, the See One,
                Do One, Teach One philosophy, the SODOTO project empowers
                medical students to address inadequate access to healthcare
                information and services, particularly in marginalized
                communities. Our passion for addressing this issue is rooted in
                a profound desire to bridge the gaps in healthcare access,
                reduce health inequalities, and promote medical knowledge and
                social responsibility. This initiative aligns with multiple
                Sustainable Development Goals (SDGs), particularly SDG 3 (Good
                Health and Well-being), and SDG 4 (Quality Education). We
                proffer innovative, sustainable, and feasible solutions to the
                challenges of healthcare and information gaps through two-pillar
                agenda:
              </p>
            </div>
          </div>

          <div className="px-[30px] flex flex-col md:flex-row pb-[10px]  ">
            <div
              className=" bg-black min-h-[200px] w-[100%] md:w-[40%] bg-cover bg-center bg-no-repeat "
              style={{ backgroundImage: `url('/images/favicon.png')` }}
            ></div>
            <div className=" w-[100%] md:w-[60%] min-h-[220px] md:min-h-[200px] flex flex-col pl-0 md:pl-[30px] text-black text-[13px] md:text-[16px] ">
              <p className=" pb-[10px] md:pb-0 ">
                At ARK, our dedicated team excels in the financial industry,
                providing expert insights on trends, regulations, and market
                conditions. We prioritize disciplined risk management and
                budgeting to ensure growth for your investments. Transparent
                review and evaluation are integral to our approach, empowering
                informed decision-making. We explore diverse opportunities,
                including alternative investments, to maximize portfolio
                diversification. Our unwavering commitment to regulatory
                compliance guarantees professional and ethical practices.
                Partner with us for tailored solutions and exceptional financial
                management.
              </p>
            </div>
          </div>
        </div>

        <div className=" flex bg-black pt-[30px] pb-[70px] flex-col items-center justify-between leading-[22px] md:leading-[25px] ">
          <p className=" pb-[30px] text-white text-[20px] md:text-[30px] ">
            WHY CHOOSE US
          </p>
          <i className=" pb-[30px] md:pb-[10px] text-white text-center w-[70%] md:w-[50%] text-[12px] md:text-[15px] ">
            Because we’re easy to work with and the most cited reason to include
            them in any portfolio is their ability to reduce risk and add
            diversification.
          </i>
          <div className=" mt-[30px] min-h-[100px] w-[90%] md:w-[80%] shadow-2xl m-auto text-white text-center text-[12px] md:text-[15px] relative ">
            <p>
              In today's ever-changing and dynamic financial landscape, staying
              ahead of the curve is crucial for success. The financial industry
              is constantly evolving, driven by advancements in technology,
              shifts in consumer behavior, and changes in regulatory frameworks.
              To navigate these complexities, it is essential to have a
              dedicated team that understands the latest trends, regulations,
              and market environments. We pride ourselves on our in-depth
              knowledge and expertise in the financial industry. Our team of
              seasoned professionals is committed to staying abreast of the
              latest developments and employing a disciplined approach to risk
              management. By diligently monitoring market conditions, assessing
              potential risks, and identifying emerging opportunities, we strive
              to ensure the growth and stability of our clients' investments.
            </p>
            <AnimatePresence
              initial={false}
              mode="wait"
              onExitComplete={() => null}
            >
              <ModalP modalOpen={isOpen} handleClose={CloseModal}>
                <div className=" p-5 text-black bg ">
                  <p className=" mt-[10px] ">
                    Budgeting is a fundamental aspect of financial management,
                    and we recognize its significance in achieving long-term
                    financial goals. Our team collaborates closely with our
                    clients to create comprehensive and tailored budgeting
                    strategies. By thoroughly analyzing their financial
                    resources, needs, and objectives, we can develop realistic
                    and achievable plans that maximize returns while mitigating
                    risks.
                  </p>
                  <p className=" mt-[10px] ">
                    Disciplined risk management is another cornerstone of our
                    investment philosophy. We understand that investment
                    involves inherent risks, but with our expertise and
                    experience, we strive to mitigate these risks through
                    careful analysis and diversification. Our team employs a
                    robust risk assessment framework, leveraging both
                    quantitative and qualitative factors to identify potential
                    vulnerabilities and implement effective risk mitigation
                    strategies we also offer our clients an ironclad capital
                    insurance to protect them from total losses of their
                    capital.
                  </p>
                  <p className=" mt-[10px] ">
                    Ultimately, our primary objective is to offer our clients a
                    comprehensive and strategic approach to investment
                    management. An effective opportunity to take advantage in of
                    the market volatility with a hands off approach.
                  </p>
                  <p className=" mt-[10px] ">
                    In a rapidly changing financial landscape, partnering with a
                    team that possesses the expertise, knowledge, and dedication
                    to adapt to these changes is crucial. We are committed to
                    remaining at the forefront of industry trends, regulations,
                    and market dynamics, enabling us to provide our clients with
                    the insights and opportunities they need to achieve their
                    financial goals and thrive in today's complex
                    financial environment.
                  </p>
                </div>
              </ModalP>
            </AnimatePresence>
            <div className=" flex justify-center items-center mt-[20px] ">
              <button
                className=" rounded-xl py-4 px-10  bg-main "
                onClick={() => {
                  isOpen ? CloseModal() : OpenModal();
                }}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
        <div className=" py-[40px] ">
          <div className=" w-[90%] m-auto flex flex-col ">
            <p className=" text-[20px] md:text-[30px] text-left md:text-center font-[800] ">
              Our Team Of Skilled Professionals
            </p>
            <p className=" py-[20px] text-black text-[13px] md:text-[17px] ">
              At ARK, we forge deep-rooted and enduring partnerships with our
              esteemed clients, steadfastly committed to fostering robust
              connections that transcend time and surpass expectations. Our
              unwavering dedication empowers us to deliver unparalleled advice,
              astute consultation, and customized solutions, ensuring our
              clients' success in a dynamic and ever-evolving landscape. With an
              unwavering focus on their prosperity and growth, we stand as a
              trusted advisor, collaborator, and ally, driven by an insatiable
              passion to exceed their aspirations and elevate their financial
              journeys to unprecedented heights.
            </p>
            <div className=" w-full pt-[20px] text-black  ">
              <div className=" min-h-[400px] flex flex-col md:flex-row justify-between w-full  ">
                <div className=" w-full md:w-[30%]  bg-main md:bg-white  border-2 border-[#ccb124] p-[10px] md:p-0 flex flex-row md:flex-col items-center ">
                  <div
                    className=" w-[30%] md:w-[60%] h-[100px] md:h-[60%] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                    style={{ backgroundImage: `url('/images/ark12.jpg')` }}
                  ></div>
                  <div className=" w-[80%] md:w-[85%] py-[10px] bg-main h-[100px] flex flex-col justify-center items-center  ">
                    <p className=" font-[700] text-white ">
                      Xiaohan Pan
                      <br /> Ph.D., CFA
                    </p>
                    <p className=" w-[60%] md:w-full text-center text-white text-[13px] md:text-[16px] ">
                      Chief Executive Officer
                    </p>
                  </div>
                </div>
                <div className="flex w-full md:w-[60%] pt-[20px] md:pt-0 text-[13px] md:text-[15px] justify-center items-center">
                  Dr Xiaohan Pan is co-founder and CEO of the firm. Xiaohan has
                  extensive knowledge and experience in UK and China’s financial
                  services industry. She worked as equity analyst at Sloane
                  Robinson, one of the largest hedge funds in Europe,
                  responsible for stock selection in the Asia-ex-Japan region.
                  She also worked at Honghui Investment and Guotai Junan
                  Securities in Shanghai, China before she settled in the UK.
                  She holds a bachelor degree from Zhejiang University and a
                  doctorate degree from the University of Cambridge, fully
                  sponsored by Gates Cambridge Scholarship. Xiaohan is a CFA
                  Charterholder, and also assumes Director of Cantab Academy,
                  Executive Director of Zhejiang UK Alumni Association and
                  Executive Director of Gates Biennial.
                </div>
              </div>

              <div className=" min-h-[400px] mt-5 flex flex-col md:flex-row justify-between w-full  ">
                <div className=" w-full md:w-[30%] bg-main md:bg-white border-2 border-[#ccb124] p-[10px] md:p-0 flex flex-row md:flex-col items-center ">
                  <div
                    className=" w-[30%] md:w-[60%] h-[100px] md:h-[60%] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                    style={{ backgroundImage: `url('/images/prof2.jpg')` }}
                  ></div>
                  <div className=" w-[80%] md:w-[85%] py-[10px] bg-main h-[100px] flex flex-col justify-center items-center  ">
                    <p className=" font-[700] text-white ">Brent S Flynn</p>
                    <p className=" w-[60%] md:w-full text-center text-white text-[13px] md:text-[16px] ">
                      Co-Founder.
                    </p>
                  </div>
                </div>
                <div className="flex w-full md:w-[60%] pt-[20px] md:pt-0 text-[13px] md:text-[15px] justify-center items-center">
                  Brent S Flynn, holding the designations of ACII and FPFS, is a
                  co-founder of the firm. He assumes the critical role of
                  overseeing compliance pertaining to investment operations and
                  associated risks. With an extensive background spanning more
                  than two decades in the financial services sector, Brent
                  possesses a remarkable proficiency as a regulatory and
                  governance specialist. His expertise is complemented by his
                  substantial experience in investment management and
                  operational aspects. Notably, he has previously held positions
                  such as investment manager and head of dealing and operations
                  at Barnett Waddingham Investments. Prior to this, he has
                  undertaken diverse responsibilities, including the role of
                  Head of Compliance at Barnett Waddingham LLP and the position
                  of Practice Director within St James’s Place Partnership.
                  Holding the distinction of being a Chartered Financial Planner
                  and a Fellow of the Society of Financial Advisers underscores
                  Brent's professional stature
                </div>
              </div>

              <div className=" min-h-[400px] my-5 flex flex-col md:flex-row justify-between w-full  ">
                <div className=" w-full md:w-[30%] bg-main md:bg-white border-2 border-[#ccb124] p-[10px] md:p-0 flex flex-row md:flex-col items-center ">
                  <div
                    className=" w-[30%] md:w-[60%] h-[100px] md:h-[60%] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                    style={{ backgroundImage: `url('/images/prof3.jpg')` }}
                  ></div>
                  <div className=" w-[80%] md:w-[85%] py-[10px] bg-main h-[100px] flex flex-col justify-center items-center  ">
                    <p className=" font-[700] text-white ">
                      Alice B Pratt
                      <br /> PACCA and MCSI.
                    </p>
                    <p className=" w-[60%] md:w-full text-center text-white text-[13px] md:text-[16px] ">
                      Co-Lead, ARK Venture & Analyst{" "}
                    </p>
                  </div>
                </div>
                <div className="flex w-full md:w-[60%] pt-[20px] md:pt-0 text-[13px] md:text-[15px] justify-center items-center">
                  Alice became a part of Ark Investment Management in the year
                  2018. Drawing from a wealth of experience, she specializes in
                  offering comprehensive advisory services to both private and
                  corporate clients, focusing on matters of asset allocation and
                  financial planning. Prior to her tenure at Ark Investment
                  Management, Alice amassed valuable professional exposure
                  during her tenure at Deloitte UK and Investec plc. Her
                  academic foundation includes a bachelor's degree in
                  Mathematics from the esteemed Imperial College London.
                  Notably, Alice is an esteemed member of the Association of
                  Chartered Certified Accountants and holds a distinguished
                  membership with the Chartered Institute for
                  Securities & Investment.
                </div>
              </div>

              <div className=" min-h-[400px] flex flex-col md:flex-row justify-between w-full  ">
                <div className=" w-full md:w-[30%] bg-main md:bg-white border-2 border-[#ccb124] p-[10px] md:p-0 flex flex-row md:flex-col items-center ">
                  <div
                    className=" w-[30%] md:w-[60%] h-[100px] md:h-[60%] bg-black rounded-full bg-center bg-cover bg-no-repeat  "
                    style={{ backgroundImage: `url('/images/prof4.jpg')` }}
                  ></div>
                  <div className=" w-[80%] md:w-[85%] py-[10px] bg-main h-[100px] flex flex-col justify-center items-center  ">
                    <p className=" font-[700] text-white ">
                      Jeremy R Kushel
                      <br /> Ph.D., CFA
                    </p>
                    <p className=" w-[60%] md:w-full text-center text-white text-[13px] md:text-[16px] ">
                      Senior Managing Director
                    </p>
                  </div>
                </div>
                <div className="flex w-full md:w-[60%] pt-[20px] md:pt-0 text-[13px] md:text-[15px] justify-center items-center">
                  Jeremy R Kushel, Senior Managing Director, currently serves as
                  the Head of the Portfolio Management Group (PMG) at Ark
                  Investment Management. In this capacity, he oversees a diverse
                  range of areas including Fixed Income (Fundamental,
                  Municipals, Financial Institutions, Index, and LDI),
                  Fundamental Equities, Private Credit, Systematic Investments,
                  Multi-Asset Strategies & Solutions, and the Private Investors
                  Businesses. He created and manages The Harbour Club which
                  Helped 34 People Generate 78 Million of Wealth in 2020.
                  Formerly, Mr. Kushel held the position of Head of Multi-Asset
                  Strategies and Global Fixed Income. Before joining us his
                  served in roles such as leading Strategic Product Management,
                  BlackRock Investment Stewardship, and the BlackRock Investment
                  Institute. He was based in London and held the position of
                  Chairman of BlackRock's International Businesses, overseeing
                  the management of the company's operations outside the
                  U.S. and Canada.
                </div>
              </div>
            </div>
          </div>
        </div>
        <i className=" p-[60px] bg-main font-[700] text-white  text-center leading-[22px] md:leading-[25px] ">
          Thank you for choosing ARK as your partner in securities, trading and
          financial management. We are excited to embark on this journey with
          you, guiding you towards a brighter financial future. Together, let's
          navigate the ever-changing financial landscape and unlock the true
          potential of your investments.
        </i>
      </div>
    </LayoutOne>
  );
}
