import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { authRoute, protectedRoute, homeRoute } from "./constants/routes";
import Landing from "./StaticPages/Landing";
import { Toaster } from "react-hot-toast";
import {
  NoAccessRoute,
  ProtectedRoute as AccessRoute,
} from "./components/Private";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import NotFound from "./StaticPages/NotFound";
import { useEffect } from "react";

export default function App() {
  useEffect(() => {
    // Check if the cache has already been cleared
    const cacheCleared = localStorage.getItem("cacheCleared");

    if (!cacheCleared) {
      // Clear the cache by forcing a hard reload
      if ("caches" in window) {
        console.log(caches.keys());

        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      // Set a flag in localStorage to indicate the cache has been cleared
      localStorage.setItem("cacheCleared", "true");

      // Force a hard reload to ensure the latest version is fetched
      window.location.reload(); // Note: `true` is deprecated but may still work in some browsers
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route index path={"/"} element={<Landing />} />
            <Route element={<NoAccessRoute />}>
              {authRoute.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                ></Route>
              ))}
            </Route>
            <Route element={<AccessRoute />}>
              {protectedRoute.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                ></Route>
              ))}
            </Route>
            <Route>
              {homeRoute.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                ></Route>
              ))}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
        <Toaster
          position={"top-right"}
          toastOptions={{ className: "react-hot-toast" }}
        />
      </PersistGate>
    </Provider>
  );
}
